import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

interface AuthState {
  token: string;
  user: object;
}

interface SingInCredentias {
  name?: string;
  email: string;
  password: string;
}

interface AuthContextData {
  user: object;
  signIn(credentials: SingInCredentias): Promise<void>;
  signUp(credentials: SingInCredentias): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@ConectoVarejo:token');
    const user = localStorage.getItem('@ConectoVarejo:user');

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }
    return {} as AuthState;
  });
  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('auth/session', { email, password });

    const { token, user } = response.data;

    localStorage.setItem('@ConectoVarejo:token', token);
    localStorage.setItem('@ConectoVarejo:user', JSON.stringify(user));

    setData({ token, user });
  }, []);

  const signUp = useCallback(async ({ name, email, password }) => {
    const response = await api.post('auth/create', { name, email, password });

    const { token, user } = response.data;

    if (token && user) {
      localStorage.setItem('@ConectoVarejo:token', token);
      localStorage.setItem('@ConectoVarejo:user', JSON.stringify(user));

      setData({ token, user });
    } else {
      setData({} as AuthState);
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@ConectoVarejo:token');
    localStorage.removeItem('@ConectoVarejo:user');

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signUp, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
